body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    color: #444;
    font-size: 16px;
    font-weight: 300;
    line-height: 180%;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@keyframes pulse {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 1
    }
}